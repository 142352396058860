 <template>
	<el-dialog title="添加网点" top="1vh" width="600px" :visible.sync="is_show_in_page">
		<el-form label-position="left" label-width="75px">
			
			<el-form-item label="网点名称">
				<el-input v-model="form.name" placeholder="请输入网点名称" clearable></el-input>
			</el-form-item>
			
			<el-form-item label="网点地址">
				<el-cascader 
					v-model="form.city" 
					placeholder="装货地省市区" 
					:options="cities"
					style="width: 100%;" filterable
				></el-cascader>
				<el-input 
					v-model="form.addr" 
					placeholder="装货地详细地址"
					style="margin-top: 2px;" clearable
				></el-input>
			</el-form-item>
			
			<el-form-item label="备注">
				<el-input v-model="form.mark" placeholder="请输入线路备注" clearable></el-input>
			</el-form-item>

			<el-form-item>
				<el-button type="primary" @click="sub">提交</el-button>
			</el-form-item>
		</el-form>
	</el-dialog>
</template>

<script>
	import {regionDataPlus,CodeToText} from 'element-china-area-data'
	export default {
		props:{
			is_show:Number,
		},
		data() {
			return {

				//是否显示
				is_show_in_page:false,

				//省市县基础数据
				cities:regionDataPlus,

				//表单
				form: {

					//网点名称
					name:'',

					//发货省市区
					city:null,

					//发货省详细地址
					addr:"",

					//备注
					mark:''
				},
			}
		},
		watch:{
			is_show(new_data){
				if(new_data){
					this.is_show_in_page=true;
					
				}else{
					this.is_show_in_page=false;
				}
			}
		},
		methods: {
			
			//提交
			sub(){

				//货物名称
				if(!this.form.name.trim()){
					this.$my.other.msg({
						type:'warning',
						str:"网点名称为空,请输入"
					});
					return;
				}

				//装货地省市县
				if(!this.$my.check.is_arr(this.form.city)){
					this.$my.other.msg({
						type:'warning',
						str:"网点城市数据为空,请选择"
					});
					return;
				}else{
					this.form.prov_name=CodeToText[this.form.city[0]];
					this.form.city_name=CodeToText[this.form.city[1]];
					this.form.county_name=CodeToText[this.form.city[2]];
				}

				//装货地详情
				if(!this.form.addr.trim()){
					this.$my.other.msg({
						type:'warning',
						str:"网点地址数据为空,请输入"
					});
					return;
				}

				//调用接口
				this.$my.net.req({
					data:{
						mod:'truck_business_outlets',
						ctr:'outlets_add_admin',
						name:this.form.name.trim(),
						prov:this.form.prov_name,
						city:this.form.city_name,
						county:this.form.county_name,
						addr:this.form.addr.trim(),
						mark:this.form.mark,
					},
					callback:(data)=>{

						//通知
						this.$emit("sub");
					}
				});
			},
		}
	}
</script>